



































































import Vue from "vue";
import Component from "vue-class-component";
import auth from "@/store/modules/user";
import groupModule from "@/store/modules/groups";
import firebase from "@/fcm";

@Component
export default class Login extends Vue {
  username = "";
  password = "";
  isLoading = false;

  async login(): Promise<void> {
    let mail = this.username;
    let password = this.password;
    this.isLoading = true;

    try {
      await auth.login({ mail, password });
      await groupModule.loadGroups();
      this.askNotifs();
      this.$toast.success(this.$t("login.logged-in").toString());
      if (this.$route.query.redirect)
        this.$router.push(this.$route.query.redirect.toString());
      else this.$router.push({ name: "Home" });
    } catch (err) {
      console.log(err);
      auth.logout();
      this.$toast.error(this.$t("login.failed").toString());
    } finally {
      this.isLoading = false;
    }
  }

  async askNotifs(): Promise<void> {
    try {
      if ("Notification" in window) {
        if (Notification.permission === "denied") return;
        else if (Notification.permission !== "granted") {
          const reply = await Notification.requestPermission();
          if (reply !== "granted") return;
        }

        const fcmToken = await firebase
          .messaging()
          .getToken({ vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY });
        auth.addFcmToken(fcmToken);
      } else {
        console.log("notifications are not supported");
      }
    } catch (err) {
      console.log(err);
    }
  }

  get loading(): boolean {
    return this.isLoading;
  }
}
